.body{
    height: 100%;
    width: 100%;
    min-height: 0px;
    overflow: hidden;
    position: relative;
    
    background: -moz-linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(24, 24, 24) 100%);
    background: -webkit-linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(24, 24, 24) 100%);
    background: linear-gradient(135deg, rgb(0, 0, 0) 0%, rgb(24, 24, 24) 100%);
    /* blue background
    background: rgb(0,28,78);
    background: -moz-linear-gradient(135deg, rgba(0,28,78,1) 0%, rgba(2,2,47,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(0,28,78,1) 0%, rgba(2,2,47,1) 100%);
    background: linear-gradient(135deg, rgba(0,28,78,1) 0%, rgba(2,2,47,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#001c4e",endColorstr="#02022f",GradientType=1);
    */ 
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

a {
    color: white;
}
a:hover{
    color: rgb(226, 226, 226);
}
a:active{
    color: rgb(131, 131, 131);
}
.footer{
    position: absolute;
    bottom: 5px;
    left: 0px;
    height: 10px;
    width: 100%;
    font-size: 8px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer *{
    margin-right: 4px;
    margin-left: 4px;
}
.companyLogo{
    height: 40px;
    margin-bottom: 4px;
}